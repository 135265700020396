import React from "react";
import Card from "react-bootstrap/Card";
import { GoPrimitiveDot } from "react-icons/go";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", color: "black" }}>
            Hey! I'm <span className="purple">Yash Patni</span>.
            I'm from <a style={{textDecoration: "none"}} href= 'https://www.google.com/maps/place/Garnet+Valley,+PA+19014/@39.8583399,-75.4783754,15z/data=!3m1!4b1!4m5!3m4!1s0x89c6e5980302d98f:0xaf488cb38d76bcfa!8m2!3d39.8583408!4d-75.4696206' target="_blank"><span className="purple"> Garnet Valley, Pennsylvania.</span></a>
             &nbsp; I am a rising junior studying <span style = {{fontWeight: 550}}>Computer Science and Mathematics</span> at The Pennsylvania State University (University Park).
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <GoPrimitiveDot /> Playing Tennis 🎾
            </li>
            <li className="about-activity">
              <GoPrimitiveDot /> Cooking and Baking 👨‍🍳
            </li>
            <li className="about-activity">
              <GoPrimitiveDot /> Playing the Guitar 🎸
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
