import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import pdf from "../../Assets/Yash-Patni.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Industry Experience</h3>
            <Resumecontent
              title="Software Developer Intern - Billtrust"
              date="May 2021 - August 2021"
              content={[
                "Currently working as the sole intern on the Machine Learning Team, focused on PDF invoice data extraction in order to attain invoice totals. Focused on implementation of Random Forest Classifiers to identify relevant invoice data using remittance information from over 900 customers. ",
              ]}
            />
            <h3 className="resume-title">Extracurricular Activities</h3>
            <Resumecontent
              title="THON Captain - THON Information Network (THINK) Developer"
              date="April 2021 - Present"
              content={[
                "Actively involved in implementing changes requested by the Finance Committee on think.thon.org",
                "Currently working on updating recommendations on how older data should be edited in the Deposits",
              ]}
            />
            <Resumecontent
              title="HackPSU – Frontend Developer"
              date="August 2020 - Present"
              content={[
                `Worked alongside the director implement modals(popups) and automating updating workshops on the livesite on a per-semester basis using HTML, CSS, and Angular.`,
                "Currently the lead frontend developer working on migration of HackPSU website from Bootstrap 4 to 5. ",
              ]}
            />
            <Resumecontent
              title="Assosiate Justice - University Park Undergraduate Association"
              date="April 2021 - Present"
              content={[
                "Work to ensure the UPUA follows ethical practices by debating and issuing Decisions of the Judicial Board on Policy Changes in the interest of fairness and equity, adjudicating internal conflicts which may arise, and ensuring the UPUA abides by its Constitution. ",
                "Serve as member of UPUA Election Commission",
              ]}
            />
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="Pennsylvania State University "
              date="2020 - 2023"
              content={[
                `GPA: 4.0/4.0`,
                "President's Freshman Award",
                "Deans List: 2020-21",
              ]}
            />
            <Resumecontent
              title="Garnet Valley High School"
              date="2018 - 2020"
              content={[
                `GPA: 4.36`,
                "National Honor Society",
                "Varsity Tennis",
                "SAT: 1510/1600",
              ]}
            />
            <h3 className="resume-title">Projects</h3>
            <Resumecontent
              title="Personal Website – Built on AWS Serverless Framework"
              content={[
                `Created a single page note taking app with React.js powered by a REST API.`,
                `Set up backend with AWS Lambda functions, Cognito User Pools, Cognito Identity Pools to manage accounts and secure file uploads, wrote and tested several Backend APIs involving creating/adding notes.`,
                "Set up frontend with React, used AWS Cognito SDK to login and signup users, and added UI Kit using Bootstrap.",
              ]}
            />
            <Resumecontent
              title="Machine Learning - Classification of Breast Cancer Tumors"
              content={[
                "Classified tumors in breast cancer patients as malignant or benign with a 95% accuracy using the Support Vector Machines algorithm, a non-probabilistic linear classifier.",
              ]}
            />
            <Resumecontent
              title="Machine Learning - Using Images to Classify Objects"
              content={[
                "Classified Yelp Reviews as one-star or five-stars with a 97% accuracy using Natural Language Processing to convert text into numerical feature vectors and use the data pipeline feature to predict off of the test set.",
              ]}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
