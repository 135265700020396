import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";
import profileimg from '../../Assets/profile.png';
function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "left", padding: "10px" }}>

          <Col
            md={7}
            style={{
              justifyContent: "left",
              paddingTop: "30px"
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              A Little About <strong className="purple">Me</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "70px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={profileimg} alt="about" className="img-fluid" />
          </Col>

        </Row>
        <h1 className="about-heading" style = {{color: "black"}}>
          Tech I like to use  <strong className="purple">a lot </strong>
        </h1>
        <Techstack />

        <h1 className="project-heading">
          Libraries/Tools I like to use
        </h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;
